﻿export function setTabComponentOverflowByIdentifier(identifier: string) {
	var tabComponent = document.getElementById(identifier);
	if (tabComponent != null) {
		setTabComponentOverflow(tabComponent);
	}
}

export function setTabComponentOverflow(tabComponent: HTMLElement) {
	if (tabComponent) {
		
		var tabLinkContainer = tabComponent.querySelector('.veva-tabs-tabs');
		if (tabLinkContainer != null) {
			var isOverflowing = tabLinkContainer.clientWidth < tabLinkContainer.scrollWidth;// || tabLinkContainer.clientHeight < tabLinkContainer.scrollHeight;
			
			if (isOverflowing) {
				tabComponent.classList.add('veva-tabs--overflowing')
			} else {
				tabComponent.classList.remove('veva-tabs--overflowing')
			}
		}
	}  
}

export function initializeTabComponent(identifier: string) {
	var tabComponent = document.getElementById(identifier);
	
	if (tabComponent) {
		var tabs = tabComponent.querySelectorAll('.veva-tabs-tab');
		var tabPanes = tabComponent.querySelectorAll('.veva-tabs-pane');	

		setTabComponentOverflow(tabComponent);

		Array.from(tabs).forEach((elementInArray: Element) => {
			var element = (elementInArray as HTMLElement);
			if (element != null) {
				
				element.addEventListener('click', (e) => {

					Array.from(tabs).forEach((elementInArray: Element) => {
						var tab = (elementInArray as HTMLElement);
						if (element == tab) tab.classList.add('veva-tabs-tab--active');
						else tab.classList.remove('veva-tabs-tab--active');
					});

					Array.from(tabPanes).forEach((element) => {
						element.classList.remove('veva-tabs-pane--show');
					});

					window.setHash('tab-' + element.dataset.hash);

					var activeTabPane = document.getElementById('veva-tabs-pane-' + element.dataset.hash);
					if (activeTabPane) {
						activeTabPane.classList.add('veva-tabs-pane--show');
					}

					e.preventDefault();
				});
			}
		});

		var activeTabPane = null;
		var activeTab = null;

		if (document.location.hash && document.location.hash.startsWith('#tab-')) {
			activeTabPane = document.getElementById('veva-tabs-pane-' + document.location.hash.replace('#tab-', ''));
		} else {
			activeTabPane = tabComponent.querySelectorAll('.veva-tabs-pane')[0];
		}

		if (document.location.hash && document.location.hash.startsWith('#tab-')) {
			var tab = document.getElementById('veva-tabs-tab-' + document.location.hash.replace('#tab-', ''));
			if (tab != null) {
				activeTab = tab
			}
		} else {
			activeTab = tabComponent.querySelectorAll('.veva-tabs-tab')[0];
		}

		if (!activeTabPane) {
			activeTabPane = tabComponent.querySelectorAll('.veva-tabs-pane')[0];
		}

		if (activeTabPane) {
			activeTabPane.classList.add('veva-tabs-pane--show');
		}

		if (!activeTab) {
			activeTab = tabComponent.querySelectorAll('.veva-tabs-link')[0];
		}

		if (activeTab) {
			activeTab.classList.add('veva-tabs-tab--active');
		}
	}
}

